.dashboard-sidebar {
  transition: 0.5s ease;
  /* overflow: hidden; */
  white-space: nowrap;
}
.expanded {
  max-width: 100%;
  opacity: 1
}
.shrunk {
  max-width: 0;
  opacity: 0
}

.custom-close-icon .icon.close {
  color: black !important;
}
